/* montserrat-100normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Montserrat Thin'),
    local('Montserrat-Thin'), 
    url('./files/montserrat-all-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-100italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Montserrat Thin Italic'),
    local('Montserrat-ThinItalic'), 
    url('./files/montserrat-all-100-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-100-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Montserrat ExtraLight'),
    local('Montserrat-ExtraLight'), 
    url('./files/montserrat-all-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Montserrat ExtraLight Italic'),
    local('Montserrat-ExtraLightItalic'), 
    url('./files/montserrat-all-200-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-200-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Montserrat Light'),
    local('Montserrat-Light'), 
    url('./files/montserrat-all-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Montserrat Light Italic'),
    local('Montserrat-LightItalic'), 
    url('./files/montserrat-all-300-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-300-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-400normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'), 
    url('./files/montserrat-all-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-400italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Montserrat Italic'),
    local('Montserrat-Italic'), 
    url('./files/montserrat-all-400-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Montserrat Medium'),
    local('Montserrat-Medium'), 
    url('./files/montserrat-all-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Montserrat Medium Italic'),
    local('Montserrat-MediumItalic'), 
    url('./files/montserrat-all-500-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-500-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Montserrat SemiBold'),
    local('Montserrat-SemiBold'), 
    url('./files/montserrat-all-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Montserrat SemiBold Italic'),
    local('Montserrat-SemiBoldItalic'), 
    url('./files/montserrat-all-600-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-600-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'), 
    url('./files/montserrat-all-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Montserrat Bold Italic'),
    local('Montserrat-BoldItalic'), 
    url('./files/montserrat-all-700-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-700-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold'),
    local('Montserrat-ExtraBold'), 
    url('./files/montserrat-all-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold Italic'),
    local('Montserrat-ExtraBoldItalic'), 
    url('./files/montserrat-all-800-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-800-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900normal - all */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Montserrat Black'),
    local('Montserrat-Black'), 
    url('./files/montserrat-all-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900italic - all */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Montserrat Black Italic'),
    local('Montserrat-BlackItalic'), 
    url('./files/montserrat-all-900-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/montserrat-all-900-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
